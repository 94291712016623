<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Detail Dokumen Persyaratan">
        <b-row class="mt-2 mb-2" align="end">
          <b-col align="end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              @click="kembaliDokumen()"
            >
              Kembali
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'data_url'">
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <!-- Jika data_url adalah gambar -->
                <b-media-aside
                  v-if="props.row.data_url.startsWith('data:image')"
                >
                  <b-img
                    ref="refPreviewEl"
                    height="150"
                    width="350"
                    class="rounded mr-2 mb-1 mb-md-0"
                    :src="props.row.data_url"
                  />
                </b-media-aside>

                <!-- Jika data_url adalah PDF -->
                <b-media-aside
                  v-else-if="
                    props.row.data_url.startsWith('data:application/pdf')
                  "
                >
                  <a
                    :href="props.row.data_url"
                    :download="`${props.row.nama_berkas}.pdf`"
                  >
                    Unduh PDF
                  </a>
                </b-media-aside>
              </b-media>
            </span>

            <span v-else-if="props.column.field === 'berkas_persyaratan'">
              <ul>
                <li v-for="item in props.row.berkas_persyaratan" :key="item">
                  {{ item }}
                </li>
              </ul>
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ props.index + 1 }}.
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-if="props.row.data_url.startsWith('data:image')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-tooltip.hover.v-success
                title="Perbesar Dokumen"
                class="btn-icon mr-2"
                @click="showModalBerkas(props)"
              >
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-if="props.row.data_url.startsWith('data:application/pdf')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.v-primary
                title="Download Dokumen"
                class="btn-icon mr-2"
                @click="downloadPDF(props.row.data_url, props.row.nama_berkas)"
              >
                <feather-icon icon="DownloadIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus Dokumen"
                class="btn-icon mr-2"
                @click="deleteInfo(props)"
              >
                  <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>

        <!-- modal detail Form SIP-->
        <b-modal
          id="modal-detail-membership-sip"
          ref="ref-payment-member-sip"
          title="Detail Dokumen Persyaratan"
          :hide-footer="true"
          size="xl"
          no-close-on-backdrop
        >
          <b-form>
            <b-form-group>
              <span> Nama SIP : </span> <b> {{ this.ini_nama_sip }} </b>
            </b-form-group>

            <b-form-group label="List Persyaratan" label-for="name">
              <ul>
                <li v-for="item in this.list_persyaratan" :key="item">
                  {{ item }}
                </li>
              </ul>
            </b-form-group>

            <b-form-group label="Berkas Persyaratan" label-for="name">
              <ul>
                <li v-for="item in this.berkas_persyaratan" :key="item">
                  {{ item }}
                </li>
              </ul>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group label="Status" label-for="name">
                  <b-form-select
                    placeholder="Pilih Status"
                    v-model="formUpdateSIP.status_sip"
                    :options="combo_status_update_form"
                    @change="updateStatusSIP()"
                  />
                </b-form-group>

                <!-- this.statusTerbit === true -->

                <b-form-group
                  label="Nomor SIP"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formUpdateSIP.nomor_sip"
                    placeholder="Masukkan Nomor SIP"
                  />
                </b-form-group>

                <b-form-group
                  label="Tanggal Terbit"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <flat-pickr
                    v-model="formUpdateSIP.tanggal_terbit"
                    class="form-control"
                    placeholder="Pilih Tanggal Terbit"
                    :config="configs.allowInput"
                  />
                </b-form-group>

                <b-form-group
                  label="Berlaku Sampai"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <flat-pickr
                    v-model="formUpdateSIP.berlaku_sampai"
                    class="form-control"
                    placeholder="Pilih Tanggal Berlaku Sampai"
                    :config="configs.allowInput"
                  />
                </b-form-group>

                <!-- this.statusTolak === true -->
                <b-form-group
                  label="Catatan"
                  label-for="name"
                  v-if="this.statusTolak === true"
                >
                  <!-- <b-form-input
                      id="name"
                      v-model="formUpdateSIP.catatan"
                      placeholder="Masukkan Catatan"
                    /> -->

                  <b-form-textarea
                    v-model="formUpdateSIP.catatan"
                    id="textarea-default"
                    placeholder="Masukkan Catatan"
                    rows="4"
                  />
                </b-form-group>

                <b-form-group
                  label="Nomor STR"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formUpdateSIP.nomor_str"
                    placeholder="Masukkan Nomor STR"
                  />
                </b-form-group>

                <b-form-group
                  label="Penerbit STR"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formUpdateSIP.penerbit_str"
                    placeholder="Masukkan Penerbit STR"
                  />
                </b-form-group>

                <b-form-group
                  label="Tanggal Terbit STR"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <flat-pickr
                    v-model="formUpdateSIP.tanggal_terbit_str"
                    class="form-control"
                    placeholder="Pilih Tanggal Terbit STR"
                    :config="configs.allowInput"
                  />
                </b-form-group>
              </b-col>

              <!-- kanan -->
              <b-col>
                <b-form-group
                  label="Nomor Rekomendasi OP"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formUpdateSIP.no_rek_op"
                    placeholder="Masukkan Nomor Rekomendasi OP"
                  />
                </b-form-group>

                <b-form-group
                  label="Waktu Praktek"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <!-- <b-form-input
                      id="name"
                      v-model="formUpdateSIP.waktu_praktik"
                      placeholder="Masukkan Waktu Praktek"
                    /> -->

                  <b-form-textarea
                    v-model="formUpdateSIP.waktu_praktik"
                    id="textarea-default"
                    placeholder="Masukkan Waktu Praktik"
                    rows="6"
                  />
                </b-form-group>

                <b-form-group
                  label="Tempat Praktek"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <!-- <b-form-input
                      id="name"
                      v-model="formUpdateSIP.nama_tempat_praktik"
                      placeholder="Masukkan Tempat Praktek"
                    /> -->

                  <b-form-textarea
                    v-model="formUpdateSIP.nama_tempat_praktik"
                    id="textarea-default"
                    placeholder="Masukkan Tempat Praktik"
                    rows="4"
                  />
                </b-form-group>

                <b-form-group
                  label="Alamat Praktek"
                  label-for="name"
                  v-if="this.statusTerbit === true"
                >
                  <!-- <b-form-input
                      id="name"
                      v-model="formUpdateSIP.alamat_praktik"
                      placeholder="Masukkan Alamat Praktek"
                    /> -->

                  <b-form-textarea
                    v-model="formUpdateSIP.alamat_praktik"
                    id="textarea-default"
                    placeholder="Masukkan Alamat Praktik"
                    rows="4"
                  />
                </b-form-group>

                <b-form-group
                  label="Cara Perawatan"
                  label-for="name"
                  v-if="
                    this.statusTerbit === true &&
                    this.ini_nama_sip === 'Penyehat Tradisional'
                  "
                >
                  <b-form-input
                    id="name"
                    v-model="formUpdateSIP.cara_perawatan"
                    placeholder="Masukkan Cara Perawatan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <b-row class="mt-2 mb-2">
            <b-col align="end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-2"
                @click="closeModal()"
              >
                Batal
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="submitUpdateSIP()"
              >
                Simpan
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- end modal detail form sip -->

        <!-- modal get all berkas-->
        <b-modal
          id="modal-detail-get-berkas"
          ref="ref-get-all-berkas"
          title="Dokumen Persyaratan"
          :hide-footer="true"
          size="xl"
          no-close-on-backdrop
        >
          <b-form>
            <b-row>
              <b-col align="center">
                <b-form-group class="mb-2">
                  <b-media no-body vertical-align="center">
                    <b-media-aside>
                      <b-img
                        ref="refPreviewEl"
                        height="450"
                        width="1100"
                        class="rounded mr-2 mb-1 mb-md-0"
                        :src="this.data_url"
                      />
                    </b-media-aside>
                  </b-media>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <b-row>
            <b-col align="end"
              ><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="hideAllBerkas()"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- end modal upload -->
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      combo_profesi: [],
      combo_syarat: [],

      data_url: "",

      log: [],
      dir: false,

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Nama SIP",
          field: "nama_sip",
          thClass: "text-center",
        },
        {
          label: "Tanggal Dibuat",
          field: "created_date",
          thClass: "text-center",
        },
        {
          label: "Nama Dokumen",
          field: "nama_berkas",
          thClass: "text-center",
        },
        {
          label: "Gambar Dokumen",
          field: "data_url",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "note",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "160px",
        },
      ],
      rows: [],
      searchTerm: "",

      name: "",
      sort: "",

      create_time: "",

      getBerkas: {},

      show: false,
      aksi: "",
      id_sip: "",

      nama: "",
      nik: "",

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      status_perizinan: "",

      selected: null,

      formCabutSIP: {
        is_cabut_sip: true,
      },

      combo_hari: [
        { value: "", text: "Pilih Hari", disabled: true },
        { value: "senin", text: "Senin" },
        { value: "selasa", text: "Selasa" },
        { value: "rabu", text: "Rabu" },
        { value: "kamis", text: "Kamis" },
        { value: "jumat", text: "Jum'at" },
        { value: "sabtu", text: "Sabtu" },
        { value: "minggu", text: "Minggu" },
      ],

      combo_status: [
        { value: "", text: "Semua SIP" },
        { value: "0", text: "SIP Masuk" },
        { value: "1", text: "SIP Terbit" },
        { value: "9", text: "SIP Ditolak" },
      ],

      combo_status_update_form: [
        { value: "", text: "Semua SIP", disabled: true },
        { value: 0, text: "SIP Masuk", disabled: true },
        { value: 1, text: "SIP Terbit" },
        { value: 9, text: "SIP Ditolak" },
      ],

      statusTerbit: false,
      statusTolak: false,

      ini_nama_sip: "",

      list_persyaratan: [],
      berkas_persyaratan: [],

      allNamaBerkas: [],
      id_sip_berkas: "",
      nama_sip_berkas: "",

      formUpdateSIP: {
        status_sip: 0,
        catatan: "",
        waktu_praktik: "",
        berlaku_sampai: "",
        nomor_sip: "",
        tanggal_terbit: "",
        nomor_str: "",
        penerbit_str: "",
        tanggal_terbit_str: "",
        no_rek_op: "",
        cara_perawatan: "",
        nama_tempat_praktik: "",
        alamat_praktik: "",
      },
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData() {
      this.show = true;

      return new Promise(() => {
        axios
          .get(
            "/pendaftar/list_berkas_sip?id_pendaftar=" +
              this.$route.params.idPendaftar +
              "&id_sip=" +
              this.$route.params.idSIP
          )
          .then((res) => {
            this.rows = res.data;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalEdit(props) {
      this.ini_nama_sip = props.row.nama_sip;
      this.id_sip = props.row.id;
      this.list_persyaratan = props.row.berkas_persyaratan;
      this.berkas_persyaratan = props.row.nama_berkas;
      this.formUpdateSIP.status_sip = props.row.status_perizinan;
      this.formUpdateSIP.catatan = props.row.catatan;

      this.formUpdateSIP.nomor_str = props.row.nomor_str;
      this.formUpdateSIP.penerbit_str = props.row.penerbit_str;
      this.formUpdateSIP.tanggal_terbit_str =
        props.row.tanggal_terbit_str.substr(0, 10);
      this.formUpdateSIP.no_rek_op = props.row.no_rek_op;
      this.formUpdateSIP.cara_perawatan = props.row.cara_perawatan;
      this.formUpdateSIP.nama_tempat_praktik = props.row.nama_tempat_praktik;
      this.formUpdateSIP.alamat_praktik = props.row.alamat_praktik;
      this.formUpdateSIP.waktu_praktik = props.row.waktu_praktik;

      if (this.formUpdateSIP.status_sip === 1) {
        this.statusTerbit = true;
        this.statusTolak = false;

        this.$refs["ref-payment-member-sip"].show();
        this.aksi = "edit";
      } else if (this.formUpdateSIP.status_sip === 9) {
        this.statusTolak = true;
        this.statusTerbit = false;

        this.$refs["ref-payment-member-sip"].show();
        this.aksi = "edit";
      } else {
        this.statusTolak = false;
        this.statusTerbit = false;

        this.$refs["ref-payment-member-sip"].show();
        this.aksi = "edit";
      }
    },

    closeModal() {
      this.$refs["ref-payment-member-sip"].hide();
    },

    submitUpdateSIP() {
      if (this.formUpdateSIP.status_sip === 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Pilih Status Ditolak atau Terbit",
            icon: "XCircleIcon",
            variant: "danger",
            text: "Silahkan Memilih Status Ditolak atau Terbit",
          },
        });
      } else if (
        (this.formUpdateSIP.status_sip === 9 &&
          this.formUpdateSIP.catatan === "") ||
        (this.formUpdateSIP.status_sip === 9 &&
          this.formUpdateSIP.catatan === null)
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Catatan Belum Anda Isi",
            icon: "XCircleIcon",
            variant: "danger",
            text: "Silahkan Mengisi Catatan Terlebih Dahulu",
          },
        });
      } else if (
        (this.formUpdateSIP.status_sip === 1 &&
          this.formUpdateSIP.waktu_praktik === "") ||
        (this.formUpdateSIP.status_sip === 1 &&
          this.formUpdateSIP.berlaku_sampai === "") ||
        (this.formUpdateSIP.status_sip === 1 &&
          this.formUpdateSIP.nomor_sip === "") ||
        (this.formUpdateSIP.status_sip === 1 &&
          this.formUpdateSIP.tanggal_terbit === "")
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Inputan Belum Lengkap",
            icon: "XCircleIcon",
            variant: "danger",
            text: "Silahkan Input Form Secara Lengkap",
          },
        });
      } else {
        return new Promise(() => {
          axios
            .put("admin/update_sip?id_sip=" + this.id_sip, this.formUpdateSIP)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ubah Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Ubah Data SIP Berhasil`,
                },
              });
              this.getData();
              this.$refs["ref-payment-member-sip"].hide();
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      }
    },

    showModalBerkas(props) {
      this.data_url = props.row.data_url;

      this.$refs["ref-get-all-berkas"].show();
    },

    hideAllBerkas() {
      this.data_url = "";

      this.$refs["ref-get-all-berkas"].hide();
    },

    pencabutanSIP(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text:
          "Untuk Melakukan Pencabutan SIP atas nama " + props.row.nama + " ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Cabut SIP",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.pencabutanSIPApi(props);
        }
      });
    },

    pencabutanSIPApi(props) {
      return new Promise(() => {
        axios
          .put("admin/cabut_sip?id_sip=" + props.row.id, this.formCabutSIP)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Pencabutan SIP Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Pencabutan SIP Berhasil`,
              },
            });
            this.getData();
          });
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
        .delete("admin/delete_berkas_sip?id_berkas=" + props.row.id + "&id_sip=" + props.row.id_sip)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Dokumen Berhasil`,
              },
            })
            this.getData();
          }).catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
            this.show = false;
          });
      });
    },

    updateStatusSIP() {
      if (this.formUpdateSIP.status_sip === 1) {
        this.statusTerbit = true;
        this.statusTolak = false;
      } else if (this.formUpdateSIP.status_sip === 9) {
        this.statusTolak = true;
        this.statusTerbit = false;
      }
    },

    downloadPDF(dataUrl, fileName) {
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = fileName + ".pdf"; // Menambahkan ekstensi .pdf pada nama file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    kembaliDokumen() {
      this.$router.push("/pembuatan-sip");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.modal-header .close {
  display: none;
}
</style>
